import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      footerData: allContentfulFooter(limit: 1) {
        edges {
          node {
            title
            twitterUrl
            facebookUrl
            linkedinUrl
          }
        }
      }
      footerLinksFirstWidget: allContentfulFooterLinksWidget1 {
        edges {
          node {
            id
            title
            path
          }
        }
      }
      footerLinksSecondWidget: allContentfulFooterLinksWidget2 {
        edges {
          node {
            id
            title
            path
          }
        }
      }
      footerLinksThirdWidget: allContentfulFooterLinksWidget3 {
        edges {
          node {
            id
            title
            path
          }
        }
      }
      footerAddressWidget: allContentfulFooterAddressWidget {
        edges {
          node {
            flagIcon {
              file {
                url
                fileName
              }
            }
            address {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      registrarLogo: allContentfulRegistrarLogo {
        edges {
          node {
            logo {
              file {
                url
                fileName
              }
            }
          }
        }
      }
    }
  `)

  const footerData = data.footerData.edges[0].node
  const footerLinksFirstWidget = data.footerLinksFirstWidget.edges
  const footerLinksSecondWidget = data.footerLinksSecondWidget.edges
  const footerLinksThirdWidget = data.footerLinksThirdWidget.edges
  const footerFirstAddressWidget = data.footerAddressWidget.edges[0].node
  const footerSecondAddressWidget = data.footerAddressWidget.edges[1].node
  const registrarLogo = data.registrarLogo.edges

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={6} className="footer-head-sec">
            <h2 dangerouslySetInnerHTML={{ __html: footerData.title }} />
            {/* <ul className="socail-icon d-flex my-5">
              <li>
                <a
                  href={footerData.twitterUrl}
                  className="pr-2"
                  target="_blank"
                >
                  <img
                    src={require("../../../static/logos/twitter-icon.svg")}
                    alt="twitter"
                  />
                </a>
              </li>
              <li>
                <a
                  href={footerData.facebookUrl}
                  className="pr-2"
                  target="_blank"
                >
                  <img
                    src={require("../../../static/logos/linkdin-icon.svg")}
                    alt="facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href={footerData.linkedinUrl}
                  className="pr-2"
                  target="_blank"
                >
                  <img
                    src={require("../../../static/logos/linkdin-icon.svg")}
                    alt=""
                  />
                </a>
              </li>
            </ul> */}
          </Col>
          {/* inside-1st-row */}
          <Col lg={6}>
            <Row>
              <Col md={4} xs={6}>
                <ul className="footer-menu-list">
                  {footerLinksFirstWidget.map(link => (
                    <li key={link.node}>
                      <Link to={`/${link.node.path}`} className="">
                        {link.node.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={4} xs={6}>
                <ul className="footer-menu-list">
                  {footerLinksSecondWidget.map(link => (
                    <li key={link.node}>
                      <Link to={`/${link.node.path}`} className="">
                        {link.node.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={4} xs={12}>
                <ul className="footer-menu-list terms-policy-menu">
                  {footerLinksThirdWidget.map(link => (
                    <li key={link.node}>
                      <Link to={`/${link.node.path}`} className="">
                        {link.node.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* inside-second-Row */}
        <Row>
          <Col lg={6} />
          <Col lg={6} className="">
            <Row>
              <Col lg={6} md={4} className="mt-lg-0 mt-4">
                <div className="pr-4 menu-address">
                  <img
                    className="mb-3"
                    src={footerFirstAddressWidget.flagIcon.file.url}
                    alt={footerFirstAddressWidget.flagIcon.file.fileName}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        footerFirstAddressWidget.address.childContentfulRichText
                          .html,
                    }}
                  />
                </div>
              </Col>

              <Col lg={4} md={6} className="mt-lg-0 mt-4 offset-lg-2 pr-md-0">
                <div className="pr-4 menu-address">
                  <img
                    className="mb-3"
                    src={footerSecondAddressWidget.flagIcon.file.url}
                    alt={footerSecondAddressWidget.flagIcon.file.fileName}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        footerSecondAddressWidget.address
                          .childContentfulRichText.html,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* inside-third-Row */}
        <Row className="mt-5 bottom-icon">
          <Col md={6} className="text-left d-flex">
            {registrarLogo.map((registrar, index) => (
              <img
                key={index}
                src={registrar.node.logo.file.url}
                alt={registrar.node.logo.file.fileName}
                className="mr-2"
                width="43px"
                height="43px"
              />
            ))}
          </Col>
          <Col md={6} className="mt-2 align-bottom footer-copyright">
            <p>© 2020 Bluebird Media AB. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
