import React, { useState } from "react"
import { Link } from "gatsby"
import { Navbar, Nav, Button, Container } from "react-bootstrap"
import "./_index.scss"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <header id="top-header" className={`${isOpen ? "open-menu" : ""}`}>
      <Container>
        <Navbar expand="md" className="px-0">
          <Navbar.Brand className="pt-0 my-auto">
            <Link to="/">
              <img
                className="img-fluid header-logo"
                src={require("../../../static/logos/site-logo.svg")}
                alt="Header Logo"
              />
            </Link>
          </Navbar.Brand>

          <div className="mob-display">
            {!isOpen && (
              <Link className="px-md-3" to="/create-account">
                <Button className="create-account-top">Create account </Button>
              </Link>
            )}

            <div className="toggle-block" onClick={toggle}>
              <div className="cta">
                <div
                  className={`toggle-btn type1 ${isOpen ? "active" : ""}`}
                ></div>
              </div>
            </div>
          </div>

          <Navbar.Collapse className={`${isOpen ? "show" : ""}`} id="nav-bar">
            <Nav className="ml-auto">
              <Link className="nav-link px-md-3" to="/website-services">
                <span className="desktop-display">Website services</span>
                <span className="mob-display">Website Services</span>
              </Link>
              <Link className="nav-link px-md-3" to="/domain-services">
                <span className="desktop-display">Domain services</span>
                <span className="mob-display">Domain Services</span>
              </Link>
              <Link className="nav-link px-md-3" to="/contact-us">
                Contact
              </Link>
              <a className="nav-link px-md-3 sign-in-link" href="/sign-in">
                Sign in
              </a>
              <Link className="px-md-3 my-auto" to="/create-account">
                <Button className="create-account-link">Create account </Button>
              </Link>
            </Nav>
            {/* <Link className="px-md-3" to="/create-account">
              <Button className="create-account-link">Create account </Button>
            </Link> */}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default Header
